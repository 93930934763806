import React from 'react'

const ContentTable = ({ table, maxItems }) => {
  return (
    <table>
      <thead>
        <tr>
          {table.map((goal) => {
            return (
              <th key={goal.title}>{goal.title}</th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {Array(maxItems).fill(0).map((content, idx) => {
          return (
            <tr key={idx}>
              {table.map((goal) => {
                const content = goal.items.length > idx ? goal.items[idx] : ''
                return (
                  <td key={goal.title + '-' + idx}><span dangerouslySetInnerHTML={{ __html: content }} /></td>
                )
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default ContentTable;
