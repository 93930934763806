import React from 'react'
import Layout from '../components/Layout'
import goals from '../assets/goals.json'
import ContentTable from '../components/ContentTable';
import ImageGallery from '../components/ImageGallery';
import s1 from '../assets/images/fotossala/img1.jpg';
import s2 from '../assets/images/fotossala/img2.jpg';
import s3 from '../assets/images/fotossala/img3.jpg';

const Definition = ({ location }) => {
  let maxItems1 = 0;
  let maxItems2 = 0;
  goals.table1.forEach((goal) => {
    if (maxItems1 < goal.items.length) maxItems1 = goal.items.length;
  });
  goals.table2.forEach((goal) => {
    if (maxItems2 < goal.items.length) maxItems2 = goal.items.length;
  });

  return (
    <Layout location={location} lang={'en'}>
      <section id="one">
        <header className="major">
          <h2>Individual music therapy sessions</h2>
          <h4>Age 3 to 12</h4>
        </header>
        <p>
          These are fully personalized music therapy sessions, in which music is used to work on the therapeutic objectives necessary to promote an improvement in the child's quality of life and educational quality. In addition, school support objectives can also be included. In this way, the child will be able to reinforce all those aspects necessary for an improvement in educational and personal development through music and play in a totally active and participative way.
        </p>
        <p>
          Music is a universal language that, without the need to speak, allows us to communicate, create a bond, bring out and manage emotions and work on many aspects in a fun and playful way.
        </p>
      </section>
      <section id="two">
        <h2>Goals</h2>
        <p>Bearing in mind that the main objective is the potential of the general development of each child, some specific objectives can be worked on, personalized for each child, which can vary over time. These specific objectives can be classified into the following areas:</p>
        <p>
          <ContentTable
            table={goals.table3}
            maxItems={maxItems1}
          />
        </p>
        <br />
        <p>
          <ContentTable
            table={goals.table4}
            maxItems={maxItems2}
          />
        </p>
      </section>
      <section id="three">
        <h2>Sessions structure</h2>
        <p>
          <ol>
            <li style={{ color: '#000' }}>Welcome/hello song</li>
            <li style={{ color: '#6fc6c6' }}>Sing, song improvisation</li>
            <li style={{ color: '#6fc6c6' }}>Improvisation with voice, instruments...</li>
            <li style={{ color: '#6fc6c6' }}>Games/music activities/stories...</li>
            <li style={{ color: '#e7782f' }}>Dance/movement</li>
            <li style={{ color: '#f8c672' }}>Relaxation/calm activity</li>
            <li style={{ color: '#000' }}>Goodbye song/activity</li>
          </ol>
        </p>
        <p>
          Depending the needs and state of the child the structure will be adapted.
          <br />
          Approximate duration 45 minutes.
        </p>
        <h3>Inside the session</h3>
        <p>
          We foster a non-judgemental environment where the children are respected and valued. They are freely able to express their feelings through the medium of music, voice and movement.
          <br />
          Whatever language is most comfortable with the child will be used throughout the session (Catalan, Spanish or English)
        </p>
      </section>
      <section id="four">
        <h2>Follow-up throughout the year</h2>
        <p>
          <h3>With families:</h3>
          <ul>
            <li> <strong>Inital interview </strong> with new families and first sessions with the child to determine objectives</li>
            <li>Delivery <strong>follow-up report</strong> at 5 - 6 months</li>
            <li><strong>Follow-up interview</strong>  at 10 - 11 months </li>
            <li>Weekly contact with families</li>
          </ul>
          <h3>Internally:</h3>
          <ul>
            <li>Goals Follow-up Table (Individual evaluation in each session)</li>
            <li>Data analysis to see the progress and needs</li>
            <li>Meetings and/or contact with other professionals (Psychologist, Speech Therapist...)</li>
          </ul>
        </p>
      </section>
      <section id="five">
        <h2>The Space</h2>
        <p>Granollers</p>
        <ImageGallery images={[s1, s2, s3]} />
      </section>
    </Layout >
  )
};

export default Definition;
